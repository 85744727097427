import { useRef, useState } from "react";
import toast from "react-hot-toast";
import { MdCancel } from 'react-icons/md';
import { RxCross2 } from 'react-icons/rx';
import JoditEditor from 'jodit-react';
import imageIcon from '../../../images/image-icon.svg'


export default function AdminProjectAddForm({setOpenAddProjectForm, setRefetch, refetch}) {
    const [selectedImages, setSelectedImages] = useState([]);
    const addEditor = useRef(null);
    const [addContent, setAddContent] = useState('');
    
    const handleProjectImages = (event) => {
        const files = event.target.files;
        const imageArray = [];

        for (let i = 0; i < files.length; i++) {
            const imageUrl = URL.createObjectURL(files[i]);
            imageArray.push(imageUrl);
        }
        setSelectedImages(imageArray);
    }

    const handleImageRemove = (index) => {
        const updatedImages = [...selectedImages];
        updatedImages.splice(index, 1);
        setSelectedImages(updatedImages);
    };

    const handleAddProject = async (e) => {
        e.preventDefault();
        const form = e.target;
        const projectName = form.projectName.value;
        const projectRole = form.projectRole.value;
        const projectType = form.projectType.value;
        const projectCompletionTime = form.projectCompletionTime.value;
        const projectLiveLink = form.projectLiveLink.value;
        const projectResponsibilities = form.projectResponsibilities.value;
        const projectShortDescription = form.projectShortDescription.value;
        const projectImages = form.projectImages.files;

        if (addContent.length <= 0 || projectImages?.length <= 0) {
            return toast.error('None of your field can be empty');
        }

        toast.loading('Adding new project...');
        setOpenAddProjectForm(false);

        const allProjectImage = [];

        try {
            for (let i = 0; i < projectImages.length; i++) {
                const data = new FormData();
                data.append('file', projectImages[i]);
                data.append('upload_preset', 'projitize_project');
                data.append('cloud_name', 'projitize');

                const response = await fetch('https://api.cloudinary.com/v1_1/projitize/image/upload', {
                    method: 'POST',
                    body: data
                });

                const result = await response.json();
                allProjectImage.push(result.url);
            }

            const addProject = {
                projectName,
                projectRole,
                projectType,
                projectCompletionTime,
                projectLiveLink,
                projectResponsibilities,
                projectShortDescription,
                projectDetailedDescription: addContent,
                projectImages: allProjectImage,
                time: new Date()
            };

            const addProjectResponse = await fetch('https://projitize.vercel.app/new-project', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify(addProject)
            });

            const data = await addProjectResponse.json();

            if (data.acknowledged) {
                form.reset();
                setRefetch(!refetch);
                setSelectedImages([]);
                toast.dismiss();
                setAddContent('');
                toast.success('New project added.', {
                    duration: 4000,
                    style: {
                        minWidth: 'fit-content'
                    }
                });
            } else {
                setOpenAddProjectForm(true);
                toast.dismiss();
                toast.error('Sorry, project not added. Please try again.', {
                    duration: 4000,
                    style: {
                        minWidth: 'fit-content'
                    }
                });
            }
        } catch (error) {
            toast.dismiss();
            toast.error('Sorry, project not added. Please try again.');
        }
    };

    return (
        <div className="add-new-project">
            <div className="add-project-heading">
                <h4>Add new Project</h4>
                <MdCancel onClick={() => setOpenAddProjectForm(false)} className='cancel-icon'></MdCancel>
            </div>
            <form onSubmit={handleAddProject} action="">
                <input type="text" placeholder='Project Name' name='projectName' required />
                <input type="text" placeholder='Role' name='projectRole' required />
                <input type="text" placeholder='Project Type' name='projectType' required />
                <input type="text" placeholder='Completion Time' name='projectCompletionTime' required />
                <input type="url" placeholder='Live Link' name='projectLiveLink' required />
                <input type="text" placeholder='Responsibilities' name='projectResponsibilities' required />
                <input type="text" placeholder='Short Description' name='projectShortDescription' required />

                {
                    selectedImages.length > 0 &&
                    <div className="all-selected-images">
                        {
                            selectedImages.map((image, index) => (
                                <div key={index} className='single-image'>
                                    <img className='selected-img' key={index} src={image} alt={`Selected ${index}`} />
                                    <RxCross2 className='remove-img' onClick={() => handleImageRemove(index)}></RxCross2>
                                </div>
                            ))
                        }
                    </div>
                }

                <div className="add-image">
                    <input onChange={handleProjectImages} id='projectImages' type="file" placeholder='Project Image' name='projectImages' accept="image/png, image/gif, image/jpg, image/svg, image/jpeg" multiple />
                    <label htmlFor="projectImages">
                        <img src={imageIcon} alt="" />
                        {
                            selectedImages.length > 0 ?
                                <p>Change Images</p> :
                                <p>Select <span>16:9</span> images for project. Supports, png, gif, jpg, svg, jpeg.</p>
                        }
                    </label>
                </div>

                <JoditEditor
                    ref={addEditor}
                    value={addContent}
                    onBlur={(newContent) => setAddContent(newContent)}
                ></JoditEditor>

                <button className='add-project-button' type='submit'>Add Project</button>
            </form>
        </div>
    )
}
