import React, { useRef, useState } from 'react'
import JoditEditor from 'jodit-react';
import imageIcon from '../../../images/image-icon.svg'
import { RxCross2 } from 'react-icons/rx';
import toast from 'react-hot-toast';


export default function AdminEditProjectForm({openEditProject, setOpenEditProject, holdPrevProjectImage, setHoldPrevProjectImage, refetch, setRefetch}) {
    const [selectedImagesEdit, setSelectedImagesEdit] = useState([]);
    const editor = useRef(null);
    const [content, setContent] = useState('');

    const handleEditCancel = () => {
        setOpenEditProject(false)
        setContent('');
    }

    const handleProjectImagesEditNew = (event) => {
        const files = event.target.files;
        const imageArray = [];

        for (let i = 0; i < files.length; i++) {
            const imageUrl = URL.createObjectURL(files[i]);
            imageArray.push(imageUrl);
        }
        setSelectedImagesEdit(imageArray);
    }

    const handlePrevImageRemoveEditProject = (index) => {
        const updatedImages = [...holdPrevProjectImage];
        updatedImages.splice(index, 1);
        setHoldPrevProjectImage(updatedImages);
    };

    const handleImageRemoveEditNew = (index) => {
        const updatedImages = [...selectedImagesEdit];
        updatedImages.splice(index, 1);
        setSelectedImagesEdit(updatedImages);
    };
    const handleEditProject = async (e) => {
        e.preventDefault();

        const form = e.target;

        const projectName = form.projectName.value;
        const projectRole = form.projectRole.value;
        const projectType = form.projectType.value;
        const projectCompletionTime = form.projectCompletionTime.value;
        const projectLiveLink = form.projectLiveLink.value;
        const projectResponsibilities = form.projectResponsibilities.value;
        const projectShortDescription = form.projectShortDescription.value;

        const projectImages = form.projectImages.files; // or const { projectImages } = selectedImagesEdit;

        if (content.length <= 0) {
            return toast.error('Project detailed description cannot be empty');
        }

        toast.loading('Editing project...');
        const projectId = openEditProject._id;

        try {
            let updatedProjectImages = [...holdPrevProjectImage];

            if (projectImages?.length > 0) {
                const newImages = await Promise.all(
                    Array.from(projectImages).map(async (image) => {
                        const data = new FormData();
                        data.append('file', image);
                        data.append('upload_preset', 'projitize_project');
                        data.append('cloud_name', 'projitize');

                        const response = await fetch('https://api.cloudinary.com/v1_1/projitize/image/upload', {
                            method: 'POST',
                            body: data
                        });

                        const cloudinaryData = await response.json();
                        return cloudinaryData.url;
                    })
                );

                updatedProjectImages = [...updatedProjectImages, ...newImages];
            }

            const updateProject = {
                projectName,
                projectRole,
                projectType,
                projectCompletionTime,
                projectLiveLink,
                projectResponsibilities,
                projectShortDescription,
                projectDetailedDescription: content,
                projectImages: updatedProjectImages
            };

            const response = await fetch(`https://projitize.vercel.app/projects/edit-project/${projectId}`, {
                method: 'PATCH',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify(updateProject)
            });

            const data = await response.json();

            if (data.modifiedCount >= 1) {
                form.reset();
                setRefetch(!refetch);
                setHoldPrevProjectImage([]);
                setSelectedImagesEdit([]);
                setOpenEditProject(null);
                setContent('');
                toast.dismiss();

                toast.success('Project edited', {
                    duration: 4000,
                    style: {
                        minWidth: 'fit-content'
                    }
                });
            } else {
                setHoldPrevProjectImage([]);
                setSelectedImagesEdit([]);
                setOpenEditProject(null);
                toast.dismiss();
                toast.error('Sorry, project not edited. Please try again.', {
                    duration: 4000,
                    style: {
                        minWidth: 'fit-content'
                    }
                });
            }
        } catch (error) {
            toast.error(error?.message);
        }
    };

    return (
        <div className={`common-popup-bg ${openEditProject && 'open'}`}>
            <div className="common-popup">
                <div className="update-hero">
                    <h4 className="common-popup-heading">Edit Project</h4>
                    <form onSubmit={handleEditProject} action="">
                        <div className="input-field">
                            <span>Project Name</span>
                            <input type="text" placeholder='Project Name' defaultValue={openEditProject?.projectName} name='projectName' required />
                        </div>
                        <div className="input-field">
                            <span>Role</span>
                            <input type="text" placeholder='Role' defaultValue={openEditProject?.projectRole} name='projectRole' required />
                        </div>
                        <div className="input-field">
                            <span>Project Type</span>
                            <input type="text" placeholder='Project Type' defaultValue={openEditProject?.projectType} name='projectType' required />
                        </div>
                        <div className="input-field">
                            <span>Completion Time</span>
                            <input type="text" placeholder='Completion Time' defaultValue={openEditProject?.projectCompletionTime} name='projectCompletionTime' required />
                        </div>
                        <div className="input-field">
                            <span>Live Link</span>
                            <input type="url" placeholder='Live Link' defaultValue={openEditProject?.projectLiveLink} name='projectLiveLink' required />
                        </div>
                        <div className="input-field">
                            <span>Responsibilities</span>
                            <input type="text" placeholder='Responsibilities' defaultValue={openEditProject?.projectResponsibilities} name='projectResponsibilities' required />
                        </div>
                        <div className="input-field">
                            <span>Short Description</span>
                            <input type="text" placeholder='Short Description' defaultValue={openEditProject?.projectShortDescription} name='projectShortDescription' required />
                        </div>
                        {
                            holdPrevProjectImage.length > 0 &&
                            <div className="input-field edit-project-prev-images">
                                <span>Previous Images</span>
                                {

                                    <div className="all-selected-images">
                                        {
                                            holdPrevProjectImage.map((image, index) => (
                                                <div key={index} className='single-image'>
                                                    <img className='selected-img' key={index} src={image} alt={`Selected ${index}`} />
                                                    <RxCross2 className='remove-img' onClick={() => handlePrevImageRemoveEditProject(index)}></RxCross2>
                                                </div>
                                            ))
                                        }
                                    </div>
                                }
                            </div>
                        }
                        {
                            selectedImagesEdit.length > 0 &&
                            <div className="input-field edit-project-prev-images">
                                <span>New Images</span>
                                <div className="all-selected-images">
                                    {
                                        selectedImagesEdit.map((image, index) => (
                                            <div key={index} className='single-image'>
                                                <img className='selected-img' key={index} src={image} alt={`Selected ${index}`} />
                                                <RxCross2 className='remove-img' onClick={() => handleImageRemoveEditNew(index)}></RxCross2>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        }

                        <div className="add-image">
                            <input onChange={handleProjectImagesEditNew} id='projectImagesEdit' type="file" placeholder='Project Image' name='projectImages' accept="image/png, image/gif, image/jpg, image/svg, image/jpeg" multiple />
                            <label htmlFor="projectImagesEdit">
                                <img src={imageIcon} alt="" />
                                {
                                    selectedImagesEdit.length > 0 ?
                                        <p>Change Images</p> :
                                        <p>Select <span>16:9</span> images for project. Supports, png, gif, jpg, svg, jpeg.</p>
                                }
                            </label>
                        </div>

                        <div className="input-field jodit">
                            <span>Short Description</span>
                        </div>
                        <JoditEditor
                            ref={editor}
                            value={content.length <= 1 ? (openEditProject?.projectDetailedDescription || '') : content}
                            onBlur={(newContent) => setContent(newContent)}
                        ></JoditEditor>

                        <div className="actions">
                            <div onClick={handleEditCancel} className='cancel'>Cancel</div>
                            <button className='submit' type='submit'>Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
