import React from 'react';
import scrollToTop from '../components/Utilities/ScrollToTop';
import PageHeader from '../components/Utilities/PageHeader';
const Benefit = () => {
    scrollToTop();
    return (
        <div className='narrow-container text-color'>
            <PageHeader title='Benefits'></PageHeader>
            Competitive Compensation: We offer competitive salaries and compensation packages that reflect the skills, experience, and value our employees bring to the team. We believe in recognizing and rewarding top talent.

            Growth and Development: At Projitize, we prioritize the growth and development of our employees. We provide opportunities for learning and skill enhancement through training programs, workshops, conferences, and mentorship programs. We encourage employees to expand their knowledge and take on new challenges.

            Career Progression: We believe in nurturing and promoting talent from within. As you demonstrate your capabilities and contribute to our success, you will have the opportunity for career advancement and growth within the organization.

            Work-Life Balance: We understand the importance of maintaining a healthy work-life balance. We offer flexible working hours, remote work options (where applicable), and encourage our employees to prioritize their well-being and personal commitments.

            Team Collaboration: We foster a collaborative and inclusive work environment where teamwork and open communication are highly valued. You'll have the opportunity to work with a diverse group of talented professionals, share ideas, and contribute to the success of our projects.

            Innovative Projects: Projitize works on cutting-edge projects that challenge the status quo and push the boundaries of creativity and technology. You'll have the opportunity to work on exciting and impactful projects that make a difference in the digital landscape.

            Employee Recognition: We believe in recognizing and celebrating the achievements of our employees. We have a culture of appreciation and regularly acknowledge outstanding performance and contributions through various recognition programs.

            Employee Benefits: In addition to competitive compensation, we offer a comprehensive benefits package that includes health insurance, retirement plans, paid time off, and other perks. We prioritize the well-being and satisfaction of our employees.

            Positive Work Environment: We strive to create a positive and inclusive work environment where everyone feels valued, respected, and supported. We believe in fostering a culture of collaboration, creativity, and mutual respect.

            Agency Culture: At Projitize, we have a vibrant and energetic agency culture. We organize team-building activities, social events, and celebrations to foster a sense of camaraderie and create a fun and engaging work atmosphere.

            These benefits highlight the value and support employees can expect when working at Projitize. Customize them based on your agency's specific offerings and company culture to attract and retain top talent.
        </div>
    );
};

export default Benefit;