import React from 'react';
import PageTopStyleBg from '../Utilities/PageTopStyleBg';
import circles from '../../images/circles.svg'
import rightArrowWhite from '../../images/rightArrowWhite.svg'
import { Link } from 'react-router-dom';
import projitize from '../../images/logoFull.svg'
import { toast } from 'react-hot-toast';


const Footer = () => {

    const handleEmail = (e) => {
        e.preventDefault();
        const form = e.target;

        const email = form.email.value;

        const contactData = {
            email,
            info: 'footerEmail',
            time: new Date()
        }

        fetch('https://projitize.vercel.app/contact-mail', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(contactData)
        })
            .then(res => res.json())
            .then(data => {
                if (data.acknowledged) {
                    form.reset();
                    toast.success('Thanks for the contact. we will reach you soon.', {
                        duration: 7000,
                        style: {
                            minWidth: 'fit-content',
                        },
                    })
                }
                else {
                    toast.error('Sorry, mail not sent. Please try again.', {
                        duration: 7000,
                        style: {
                            minWidth: 'fit-content',
                        },
                    })
                }
            })
            .catch(error => toast.error(error?.message))

    }

    return (
        <div className='footer'>
            <PageTopStyleBg></PageTopStyleBg>
            <img src={circles} alt="" className="circles" />

            <div className="container">

                <div className="heading">
                    <div className="logo">
                        <Link to='/'><img src={projitize} alt="" /></Link>
                    </div>
                    <div className="get-started">
                        <h3>Ready to get started?</h3>
                        <Link to='/contact-us'>
                            <button className="custom-button">Get Started</button>
                        </Link>
                    </div>
                </div>

                <div className="email-links">
                    <div className="enter-email">
                        <h3 className="email-heading">Enter Your Email Address <br /> to make us communicate with you</h3>

                        <form onSubmit={handleEmail} action="">
                            <input type="email" name='email' placeholder='Email Address' required />
                            <button type='submit'><img src={rightArrowWhite} alt="" /></button>
                        </form>
                    </div>

                    <div className="links">
                        <div className="service column">
                            <h5 className="link-title">Service</h5>
                            <Link className='link' to='/home#services'>App Development</Link>
                            <Link className='link' to='/home#services'>Web Development</Link>
                            <Link className='link' to='/home#services'>Web Design</Link>
                            <Link className='link' to='/home#services'>Digital Marketing</Link>
                        </div>
                        <div className="about-us column">
                            <h5 className="link-title">About Us</h5>
                            <Link className='link' to='/our-story'>Our Story</Link>
                            <Link className='link' to='/benefits'>Benefit</Link>
                            <Link className='link' to='/about-us'>Team</Link>
                            <Link className='link' to='/carrier'>Carrier</Link>
                        </div>
                        <div className="help column">
                            <h5 className="link-title">Help</h5>
                            <Link className='link' to='/'>FAQ</Link>
                            <Link className='link' to='/contact-us'>Contact</Link>
                        </div>
                    </div>
                </div>

                <div className="terms-policy">
                    <Link to='/terms-and-conditions'>Terms and Conditions</Link>
                    <Link to='/privacy-policy'>Privacy Policy</Link>
                </div>


                <p className="copyright">&copy; {new Date().getFullYear()}, Projitize, All rights Reserved</p>
            </div>
        </div>
    );
};

export default Footer;