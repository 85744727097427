import React from 'react';
import { Link, useLoaderData } from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import arrowLeft from '../../images/arrowLeft.svg'

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import scrollToTop from '../Utilities/ScrollToTop';

const ProjectDetails = () => {
    scrollToTop();
    const projectData = useLoaderData();
    const {
        projectName,
        projectRole,
        projectType,
        projectCompletionTime,
        projectLiveLink,
        projectResponsibilities,
        projectShortDescription,
        projectDetailedDescription,
        projectImages } = projectData;
    return (
        <div>


            <div className="container pt-90 project-info">
                <div className="project-name">
                    <h1 className='grandSlang'>{projectName}</h1>
                    {/* <Link to='/projects' className="go-back-to-projects">
                        <img src={arrowLeft} alt="arrowLeft" />
                    </Link> */}
                </div>
                <div className="thumbnail-othersInfo">
                    <div className="thumbnail">
                        <img src={projectImages[0]} alt="" />
                    </div>
                    <div className="others-info">
                        <div className="role info">
                            <p className="title">Role</p>
                            <p className="data">{projectRole}</p>
                        </div>
                        <div className="type info">
                            <p className="title">Type</p>
                            <p className="data">{projectType}</p>
                        </div>
                        <div className="responsibilities info">
                            <p className="title">Responsibilities</p>
                            <p className="data">{projectResponsibilities}</p>
                        </div>
                        <div className="completion-time info">
                            <p className="title">Completion Time</p>
                            <p className="data">{projectCompletionTime}</p>
                        </div>
                        <div className="url info">
                            <p className="title">URL</p>
                            <a href={projectLiveLink} className="data" target='_blank'>{projectLiveLink.length <= 35 ? projectLiveLink : `${projectLiveLink.slice(0, 35)}...`}
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="narrow-container detailed-info">
                <p className="short-description grandSlang">{projectShortDescription}</p>
                <div className="image-slider">
                    <Swiper
                        pagination={{
                            dynamicBullets: true,
                        }}
                        navigation={true}
                        modules={[Navigation, Pagination]}
                        className="project-img">
                        {
                            projectImages?.slice(1).map((image, i) =>
                                <SwiperSlide key={i}>
                                    <img src={image} alt="" className="project-img" />
                                </SwiperSlide>
                            )
                        }
                    </Swiper>
                </div>
                <div className="detailed-description">
                    <div dangerouslySetInnerHTML={{ __html: projectDetailedDescription }}></div>
                </div>
                <div className="check-project">
                    <p className="title grandSlang">Check this project</p>
                    <a className='grandSlang' href={projectLiveLink} target='_blank'>{projectLiveLink.length <= 35 ? projectLiveLink : `${projectLiveLink.slice(0, 35)}...`}</a>
                </div>
            </div>
        </div>
    );
};

export default ProjectDetails;