import React from 'react';
import scrollToTop from '../components/Utilities/ScrollToTop';
import PageHeader from '../components/Utilities/PageHeader';
const OurStory = () => {
    scrollToTop();
    return (
        <div className='narrow-container text-color'>
            <PageHeader title='Our Story'></PageHeader>
            Our Story - Empowering Your Digital Presence

            At Projitize, we are passionate about empowering businesses and individuals with cutting-edge digital solutions. We are a dynamic team of creative professionals specializing in app development, web development, UI/UX design, graphics design, and digital marketing. With a focus on delivering exceptional results, we strive to transform your ideas into impactful realities.

            Our journey began with a shared vision of creating a digital agency that not only excels in technical expertise but also prioritizes customer satisfaction. We understand that in today's fast-paced digital world, having a strong online presence is crucial for success. That's why we are committed to providing innovative solutions tailored to your specific needs.

            Our team consists of talented individuals with diverse backgrounds, expertise, and a deep understanding of the latest industry trends. We believe in collaborative partnerships and work closely with our clients to develop strategies that align with their goals and drive tangible results.

            When you choose Projitize, you can expect a personalized approach, attention to detail, and a commitment to excellence. We take the time to understand your unique requirements, allowing us to craft bespoke solutions that effectively represent your brand and captivate your target audience.

            Whether you are a startup looking to make your mark, an established business aiming to enhance your digital presence, or an individual seeking professional design services, we have the skills and experience to exceed your expectations. Our comprehensive range of services ensures that we can support you at every stage of your digital journey.

            But Projitize is more than just a service provider - we are your trusted partner. We believe in building long-term relationships based on transparency, integrity, and open communication. Your success is our success, and we are dedicated to helping you achieve your business objectives.

            We invite you to explore our website, browse through our portfolio, and discover how Projitize can elevate your digital presence. Contact us today, and let's embark on an exciting journey together!
        </div>
    );
};

export default OurStory;