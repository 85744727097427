import React from 'react';
import scrollToTop from '../components/Utilities/ScrollToTop';
import PageHeader from '../components/Utilities/PageHeader';
const Carrier = () => {
    scrollToTop();
    return (
        <div className='narrow-container text-color'>
            <PageHeader title='Carrier'></PageHeader>
            Join Our Team - Unlock Your Potential

            At Projitize, we believe in the power of talent, creativity, and teamwork. We are constantly on the lookout for passionate individuals who are driven to make a difference in the digital world. If you are ready to take your career to new heights and be a part of an innovative and dynamic team, we invite you to join us on our journey.

            Why Work with Us?

            Collaborative Environment: At Projitize, we foster a collaborative work culture where ideas are valued, and every team member has a voice. We believe in the power of synergy and encourage open communication and mutual respect.

            Cutting-Edge Projects: As a part of our team, you will have the opportunity to work on exciting and challenging projects across various industries. From app development and web design to digital marketing campaigns, you will be at the forefront of innovation.

            Continuous Learning: We are committed to the growth and development of our team members. Through training programs, workshops, and industry conferences, we provide opportunities for continuous learning and skill enhancement.

            Impactful Work: Projitize's mission is to empower businesses and individuals through digital solutions. When you join our team, you become a catalyst for change, helping our clients achieve their goals and make a lasting impact in the digital landscape.

            Work-Life Balance: We understand the importance of maintaining a healthy work-life balance. We strive to create an environment where you can thrive professionally while also having time for personal pursuits and quality time with your loved ones.

            Current Openings:

            App Developer: We are looking for a talented and experienced app developer to join our team. You should have a strong background in mobile app development, proficiency in various programming languages, and a keen eye for detail.

            UI/UX Designer: Are you passionate about creating visually stunning and intuitive user experiences? We are seeking a creative UI/UX designer with a strong portfolio and expertise in design tools and principles.

            Digital Marketer: If you have a passion for digital marketing strategies, data analytics, and driving business growth, we want to hear from you. We are searching for a digital marketer who can develop and execute comprehensive marketing campaigns across various channels.

            How to Apply:

            If you are excited about the prospect of joining our team and making an impact in the digital world, we would love to hear from you. Please send your resume, portfolio (if applicable), and a brief cover letter outlining your interest and relevant experience to [email protected] Please mention the position you are applying for in the subject line.

            At Projitize, we value diversity, equal opportunity, and inclusivity. We encourage individuals from all backgrounds to apply.
        </div>
    );
};

export default Carrier;