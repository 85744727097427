import React, { useContext, useEffect, useState } from 'react';
import AdminPageTitle from '../Utilities/AdminPageTitle';
import { AuthContext } from '../../contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import copy from '../../images/copy.svg';

const AdminProfile = () => {
    const { userSignOut } = useContext(AuthContext)
    const [userEmail, setUserEmail] = useState([])

    useEffect(() => {
        fetch('https://projitize.vercel.app/footer-mail')
            .then(res => res.json())
            .then(data => setUserEmail(data))
    }, [])


    const navigate = useNavigate();

    const handleLogout = () => {
        userSignOut()
            .then(() => {
                navigate('/login')
                toast.error('logged out')
            })
            .catch(error => {
                toast.error(error?.message);
            })
    }

    const handleCopyMail = async (mail) => {
        navigator.clipboard.writeText(mail)
        toast.success('copied')
    };

    const copyCollectionToCSV = () => {
        const csvContent = userEmail.map(document => document.fieldToCopy).join('\n');

        const element = document.createElement('a');
        const file = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
        element.href = URL.createObjectURL(file);
        element.download = 'Emails.csv';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    };

    return (
        <div className='admin-container'>
            <AdminPageTitle title='Admin Profile Section'></AdminPageTitle>
            <div className="logout">
                <button className="custom-button" onClick={handleLogout}>Logout</button>
            </div>


            <div className="admin-footer-mail admin-common">
                <div className="admin-footer-email common-section">
                    <div className="heading">
                        <p className="title">Emails <span className='number-count'>{userEmail.length}</span></p>
                        <div className="actions">

                            {
                                userEmail.length > 0 &&
                                <p className='mark-copy' onClick={copyCollectionToCSV}>Copy All</p>
                            }
                        </div>
                    </div>
                    <div className="body">
                        {
                            userEmail.length <= 0 ? <p className='not-available'>No mail available</p> :
                                userEmail.map((mail, i) =>
                                    <div className='email' key={mail._id}>
                                        <div className="mail-data">
                                            <p className="content-count">{i + 1}</p>
                                            <p className="content-title">{mail.email}</p>
                                        </div>

                                        <img onClick={() => handleCopyMail(mail.email)} className='copy-icon' src={copy} alt="" />
                                    </div>
                                )
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminProfile;