import React from 'react';

const PageHeader = ({ title }) => {
    return (
        <div>
            <div className="page-bg">
                <h1 className="narrow-container title">{title}</h1>
            </div>

            <div className="page-bg-color"></div>
        </div>
    );
};

export default PageHeader;