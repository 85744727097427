import React, { useEffect, useState } from 'react';
import PageTopStyleBg from '../Utilities/PageTopStyleBg';
import { Link } from 'react-router-dom';

import heroRightArrow from '../../images/heroRightArrow.svg'
import heroShape from '../../images/heroShape.svg'
import heroBottom from '../../images/heroBottom.svg'

const Hero = () => {


    const [hero, setHero] = useState([]);
    useEffect(() => {
        fetch('https://projitize.vercel.app/home-data/hero')
            .then(res => res.json())
            .then(data => {
                setHero(data)
            })
    }, [])

    return (
        <div>
            <div className="wide-container">
                <div className="hero-section">
                    <div className="top-items">
                        <h1 className="heading grandSlang">Unleash Digital <span className='color'>Brilliance</span>. Your <span className='color'> Gateway</span> to Custom Apps, Websites, and Striking Designs.</h1>
                        <p className="description">{hero.description}</p>

                        <Link to='/projects' className="cta">
                            <button className="button">Check Our Works</button>
                            <div className="right-arrow">
                                <img src={heroRightArrow} alt="" />
                            </div>
                        </Link>


                        <img className='shape-one' src={heroShape} alt="" />
                        <img className='shape-two' src={heroShape} alt="" />
                    </div>
                    <div className="hero-bottom-img">
                        <img src={heroBottom} alt="" />
                    </div>
                    <PageTopStyleBg></PageTopStyleBg>
                </div>
            </div>
        </div>
    );
};

export default Hero;