import React from 'react'
import toast from 'react-hot-toast';

export default function AdminProjectHeadingUpdate({ openEditHeading, setOpenEditHeading, projectsHeading, setRefetch, refetch }) {
    const handleHeadingUpdate = (e) => {
        e.preventDefault();
        const form = e.target;
        toast.loading('Updating projects heading data ...')

        const title = form.title.value;
        const description = form.description.value;
        const updateHeading = {
            title,
            description
        }

        fetch(`https://projitize.vercel.app/projects/update-heading`, {
            method: 'PATCH',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(updateHeading)
        })
            .then(res => res.json())
            .then(data => {
                if (data.modifiedCount >= 1) {
                    setRefetch(!refetch);
                    setOpenEditHeading(false)
                    toast.dismiss();
                    toast.success('Data Updated')
                }
                else {
                    setOpenEditHeading(true)
                    toast.dismiss();
                    toast.error('Sorry, data not updated')
                }
            })
    }
    return (
        <div className={`common-popup-bg ${openEditHeading && 'open'}`}>
            <div className="common-popup">
                <div className="update-hero">
                    <h4 className="common-popup-heading">Update Projects Heading</h4>
                    <form onSubmit={handleHeadingUpdate} action="">
                        <div className="input-field">
                            <span>Title</span>
                            <textarea type="text" defaultValue={projectsHeading?.title} placeholder='Title' name='title' required ></textarea>
                        </div>
                        <div className="input-field">
                            <span>Description</span>
                            <textarea type="text" defaultValue={projectsHeading?.description} placeholder='Description' name='description' required ></textarea>
                        </div>

                        <div className="actions">
                            <div onClick={() => setOpenEditHeading(false)} className='cancel'>Cancel</div>
                            <button className='submit' type='submit'>Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
