import React, { useEffect, useState } from 'react';
import AdminPageTitle from '../Utilities/AdminPageTitle';

const AdminFooter = () => {
    const [userEmail, setUserEmail] = useState([])
    useEffect(() => {
        fetch('https://projitize.vercel.app/footer-mail')
            .then(res => res.json())
            .then(data => setUserEmail(data))
    }, [])


    return (
        <div className='admin-container'>
            <AdminPageTitle title='Admin Footer Section'></AdminPageTitle>
        </div>
    );
};

export default AdminFooter;