import React, { useEffect, useState } from 'react';
import AdminPageTitle from '../Utilities/AdminPageTitle';
import edit from '../../images/edit.svg'
import deleteIcon from '../../images/Delete.svg'
import close from '../../images/close.svg'
import { toast } from 'react-hot-toast';
import AdminProjectAddForm from './AdminProject/AdminProjectAddForm';
import AdminEditProjectForm from './AdminProject/AdminEditProjectForm';
import AdminProjectHeadingUpdate from './AdminProject/AdminProjectHeadingUpdate';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';


const AdminProjects = () => {
    const [openAddProjectForm, setOpenAddProjectForm] = useState(false);
    const [openEditHeading, setOpenEditHeading] = useState(false);
    const [refetch, setRefetch] = useState(false);
    const [deleteProject, setDeleteProject] = useState(null);
    const [allProjects, setAllProjects] = useState([]);
    const [projectsHeading, setProjectsHeading] = useState([]);
    const [openEditProject, setOpenEditProject] = useState(null);
    const [holdPrevProjectImage, setHoldPrevProjectImage] = useState([]);

    useEffect(() => {
        fetch('https://projitize.vercel.app/projects/heading')
            .then(res => res.json())
            .then(data => setProjectsHeading(data))
    }, [refetch])

    useEffect(() => {
        fetch('https://projitize.vercel.app/all-project')
            .then(res => res.json())
            .then(data => {
                const result = [...data];
                result?.sort((a, b) => a?.order - b?.order);
                setAllProjects(result);
            })
    }, [refetch])


    const handleProjectDelete = () => {
        toast.loading('deleting project...');
        fetch(`https://projitize.vercel.app/delete-project/${deleteProject}`, {
            method: 'DELETE',
            headers: {
                'content-type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(data => {
                if (data.deletedCount >= 1) {
                    setDeleteProject(null)
                    setRefetch(!refetch);
                    toast.dismiss();
                    toast.error('Project Deleted')
                }
            })
    }

    const handleProjectEditOpen = (project) => {
        let allProjectImages = [];
        setOpenEditProject(project)
        if (project?.projectImages?.length >= 1) {
            allProjectImages = [...project.projectImages]
            setHoldPrevProjectImage(allProjectImages)
        }
    }

    const handleUpdateTheOrder = (projectData) => {
        for(let i=0; i<projectData?.length; i++)projectData[i]['order'] = i+1;
        
        fetch(`https://projitize.vercel.app/projects/update-order`, {
            method: 'PATCH',
            body: JSON.stringify(projectData),
            headers: {
                'content-type': 'application/json'
            }
        }).then((res) => {
            res.json().then((result) => {
                if(result?.success){
                    toast.success(result?.message);
                }
                else{
                    toast.error(result?.message);
                }

            })
        }).catch((err) => {
            toast.error(err?.message);
        })
    }

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;

        const reorderedProjects = Array.from(allProjects);
        const [movedProject] = reorderedProjects.splice(result.source.index, 1);
        reorderedProjects.splice(result.destination.index, 0, movedProject);

        handleUpdateTheOrder(reorderedProjects);

        setAllProjects(reorderedProjects);
    };



    return (
        <div>
            <div className={`common-popup-bg ${openAddProjectForm && 'open'}`}>
                <div className="common-popup">
                    <AdminProjectAddForm setOpenAddProjectForm={setOpenAddProjectForm} setRefetch={setRefetch} refetch={refetch} />
                </div>
            </div>

            <AdminEditProjectForm openEditProject={openEditProject} setOpenEditProject={setOpenEditProject} holdPrevProjectImage={holdPrevProjectImage} setHoldPrevProjectImage={setHoldPrevProjectImage}
                refetch={refetch} setRefetch={setRefetch} />

            <div className={`confirm-popup ${deleteProject && 'open'}`}>
                <div className="confirm-box">
                    <p className="message">Are you sure you want to <span>delete</span>?</p>
                    <div className="actions">
                        <button onClick={() => setDeleteProject(null)} className="cancel">Cancel</button>
                        <button onClick={handleProjectDelete} className="delete">Delete</button>
                    </div>
                </div>
            </div>

            <AdminProjectHeadingUpdate openEditHeading={openEditHeading} setOpenEditHeading={setOpenEditHeading} projectsHeading={projectsHeading} setRefetch={setRefetch} refetch={refetch} />



            <div className='admin-container'>
                <AdminPageTitle title='Admin Projects Section'></AdminPageTitle>

                <div className="admin-projects admin-common">

                    <div className="admin-projects-top common-section">
                        <div className="heading">
                            <p className="title">Heading</p>
                            <div className="actions">
                                <img onClick={() => setOpenEditHeading(true)} src={edit} alt="" className="edit" />
                            </div>
                        </div>

                        <div className="body">
                            <div className="data">
                                <p className="content-title">Title:</p>
                                <p className="content-data">{projectsHeading.title || 'loading ...'}</p>
                            </div>
                            <div className="data">
                                <p className="content-title">Description:</p>
                                <p className="content-data">{projectsHeading.description || 'loading ...'}</p>
                            </div>
                        </div>
                    </div>

                    <DragDropContext onDragEnd={handleOnDragEnd}>
                        <div className="admin-projects-heading common-section">
                            <div className="heading">
                                <p className="title">All Projects <span className='number-count'>{allProjects.length}</span></p>
                                <div className="actions">
                                    <img onClick={() => setOpenAddProjectForm(true)} src={close} alt="" className="plus" />
                                </div>
                            </div>
                            {allProjects.length <= 0 ? <div className='body'> <p className='not-available'>No Project available</p> </div> :
                                <Droppable droppableId="projects43">
                                    {(provided) => (
                                        <div {...provided.droppableProps} ref={provided.innerRef} className="body">

                                            {
                                                allProjects.map((project, index) => (
                                                    <Draggable key={project._id} draggableId={project._id} index={index}>
                                                        {(provided) => (
                                                            <div className="data" ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}>
                                                                <p className="count">{index + 1}</p>
                                                                <div className="project-img">
                                                                    <img src={project.projectImages[0]} alt="" />
                                                                </div>
                                                                <p className="project-name">{project.projectName}</p>
                                                                <p className="project-type">{project.projectType}</p>
                                                                <div className="edit-delete-icon">
                                                                    <img onClick={() => handleProjectEditOpen(project)} src={edit} alt="" />
                                                                    <img onClick={() => setDeleteProject(project._id)} src={deleteIcon} alt="" className='delete' />
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                )
                                                )
                                            }
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            }
                        </div>
                    </DragDropContext>
                </div>


            </div>
        </div>
    );
};

export default AdminProjects;