import React, { useEffect, useState } from "react";
import { MdOutlineArrowOutward } from "react-icons/md";

import { Link } from "react-router-dom";

const AllProjects = () => {

  const [allProjects, setAllProjects] = useState([]);
  useEffect(() => {
    fetch("https://projitize.vercel.app/all-project")
      .then((res) => res.json())
      .then((data) => {
        setAllProjects(data);
      });
  }, []);


  return (
    <div>
      <div className="container">
        <div className="all-projects">
          <div className="project-data">
            {allProjects.map((project) => (
              <div key={project._id} className="project">
                <div className="project-title">
                  <div className="project-bulletpoint"></div>
                  <p>{project.projectType}</p>
                </div>
                <Link to={`/project/${project?._id}`} className="project-img">
                  <img src={project.projectImages[0]} alt="project" />
                </Link>
                <div className="project-details">
                  <div className="project-text">
                    <Link to={`/project/${project?._id}`} className="project-name">
                      {project?.projectName}
                    </Link>
                    <br />
                    <div className="year-live-link">
                      <small className="project-year">
                        {project.time.slice(0, 4)}
                      </small>
                      <small>
                        <a href={project?.projectLiveLink} target='_blank' rel="noreferrer" className="live-link">
                          {project?.projectLiveLink.length <= 35 ? project?.projectLiveLink : `${project?.projectLiveLink.slice(0, 35)}...`}
                        </a>
                      </small>
                    </div>

                  </div>
                  <Link to={`/project/${project?._id}`}>
                    <MdOutlineArrowOutward className="project-icon" />
                  </Link>


                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllProjects;
